import { AbstractControl } from '@angular/forms';

export class AppUtils {
    public static filterArrayByString(mainArr, searchText) {
        if (searchText === '') {
            return mainArr;
        }

        searchText = searchText.toLowerCase();

        return mainArr.filter(itemObj => {
            return this.searchInObj(itemObj, searchText);
        });
    }

    public static searchInObj(itemObj, searchText) {
        for (const prop in itemObj) {
            if (!itemObj.hasOwnProperty(prop)) {
                continue;
            }

            const value = itemObj[prop];

            if (typeof value === 'string') {
                if (this.searchInString(value, searchText)) {
                    return true;
                }
            } else if (Array.isArray(value)) {
                if (this.searchInArray(value, searchText)) {
                    return true;
                }
            }

            if (typeof value === 'object') {
                if (this.searchInObj(value, searchText)) {
                    return true;
                }
            }
        }
    }

    public static searchInArray(arr, searchText) {
        for (const value of arr) {
            if (typeof value === 'string') {
                if (this.searchInString(value, searchText)) {
                    return true;
                }
            }

            if (typeof value === 'object') {
                if (this.searchInObj(value, searchText)) {
                    return true;
                }
            }
        }
    }

    public static searchInString(value, searchText) {
        return value.toLowerCase().includes(searchText);
    }

    public static generateGUID() {
        function S4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return S4() + S4();
    }

    public static toggleInArray(item, array) {
        if (array.indexOf(item) === -1) {
            array.push(item);
        } else {
            array.splice(array.indexOf(item), 1);
        }
    }

    public static handleize(text) {
        return text
            .toString()
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(/[^\w\-]+/g, '') // Remove all non-word chars
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, ''); // Trim - from end of text
    }
    public static showErrors(errors: any): string {
        if (this.isObject(errors)) {
            // tslint:disable-next-line:forin
            for (const i in errors) {
                return errors[i][0];
            }
        }
    }

    public static showError(error: any): string {
        if (this.isObject(error)) {
            return error.message;
        }
    }

    public static getScrollY() {
        let scrOfY = 0;
        if (typeof window.pageYOffset === 'number') {
            scrOfY = window.pageYOffset;
        } else if (document.body && document.body.scrollTop) {
            scrOfY = document.body.scrollTop;
        }
        return scrOfY;
    }
    public static isObject(val) {
        if (val === null) {
            return false;
        }
        return typeof val === 'function' || typeof val === 'object';
    }

    public static validateObjectUbigeo(control: AbstractControl) {
        if (!control.parent || !control) {
            return;
        }
        const ubigeo_id = control.parent.get('ubigeo_id');
        if (!ubigeo_id) {
            return;
        }

        if (ubigeo_id.value === null) {
            return {
                required: true
            };
        }

        if (
            typeof ubigeo_id.value === 'function' ||
            typeof ubigeo_id.value === 'object'
        ) {
            return;
        }

        return {
            required: true
        };
    }
    
    public static validateObjectUbigeoDepartment(control: AbstractControl) {
        if (!control.parent || !control) {
            return;
        }
        const department_id = control.parent.get('department_id');
        if (!department_id) {
            return;
        }

        if (department_id.value === null) {
            return {
                required: true
            };
        }

        if (
            typeof department_id.value === 'function' ||
            typeof department_id.value === 'object'
        ) {
            return;
        }

        return {
            required: true
        };
    }

    public static validateObjectUbigeoProvince(control: AbstractControl) {
        if (!control.parent || !control) {
            return;
        }
        const province_id = control.parent.get('province_id');
        if (!province_id) {
            return;
        }

        if (province_id.value === null) {
            return {
                required: true
            };
        }

        if (
            typeof province_id.value === 'function' ||
            typeof province_id.value === 'object'
        ) {
            return;
        }

        return {
            required: true
        };
    }

    public static validateObjectBank(control: AbstractControl) {
        if (!control.parent || !control) {
            return;
        }
        const bank_id = control.parent.get('bank_id');
        if (!bank_id) {
            return;
        }

        if (bank_id.value === null) {
            return {
                isEmpty: true
            };
        }
        const value = String(bank_id.value);
        if (value.length === 0) {
            return {
                isEmpty: true
            };
        }

        const _v =
            value.length !== 0 &&
            (typeof bank_id.value === 'function' ||
                typeof bank_id.value === 'object');

        if (!_v) {
            return {
                required: true,
                isEmpty: false
            };
        }

        return;
    }

    public static validateObjectAddress(control: AbstractControl) {
        if (!control.parent || !control) {
            return;
        }

        const address = control.parent.get('address');
        if (!address) {
            return;
        }

        if (address.value === null) {
            return {
                required: true
            };
        }

        const latitud = control.parent.get('latitud');
        if (!latitud) {
            return;
        }

        const longitud = control.parent.get('longitud');
        if (!longitud) {
            return;
        }

        const _latitudValue = Number(latitud.value);
        const _longitudValue = Number(longitud.value);
        if (_latitudValue !== 0 && _longitudValue !== 0) {
            return;
        }
        return {
            required: true
        };
    }

    public static removeEstension(value: string) {
        const array = value.split('.');
        if (array.length > 0) {
            return array[0];
        }
        return value;
    }

    public static validateOwnerPlace(control: AbstractControl) {

        if (!control.parent || !control) {
            return;
        }

        const flag_owner_place = control.parent.get('flag_owner_place');
        if (!flag_owner_place) {
            return;
        }

        const term = control.parent.get('term');
        if (!term) {
            return;
        }

        if (flag_owner_place.value === '1') {
            term.setErrors(null);
        } else {
            term.setErrors({ required: true });
        }

    }
}
