import { AbstractControl, Validators, FormControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

export class AppValidate {
    public static Email(control: FormControl) {
        const _email: AbstractControl = control;
        if (!_email) {
            return;
        }
        _email.valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
            if (val !== '') {
                _email.setValidators([Validators.email]);
            } else {
                _email.clearValidators();
            }
            _email.updateValueAndValidity();
        });
    }
    public static Number(control: FormControl) {
        const _input: AbstractControl = control;
        if (!_input) {
            return;
        }
        _input.valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
            if (val !== '') {
                _input.setValidators([
                    Validators.pattern(/^[1-9]+[0-9]*$/)
                ]);
            } else {
                _input.clearValidators();
            }
            _input.updateValueAndValidity();
        });
    }

    public static DNI(control: FormControl, min = 8) {
        const _input: AbstractControl = control;
        if (!_input) {
            return;
        }
        _input.valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
            if (val !== '') {
                _input.setValidators([
                    Validators.minLength(min),
                ]);
            } else {
                _input.clearValidators();
            }
            _input.updateValueAndValidity();
        });
    }

    public static Percent(
        control: FormControl
    ) {
        const _input: AbstractControl = control;
        if (!_input) {
            return;
        }
        _input.valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
            if (val !== '') {
                _input.setValidators([
                    Validators.pattern(/^[1-9]+[0-9]*$/),
                    Validators.max(100)
                ]);
            } else {
                _input.clearValidators();
            }
            _input.updateValueAndValidity();
        });
    }

    public static Decimal(control: FormControl) {
        const _input: AbstractControl = control;
        if (!_input) {
            return;
        }
        _input.valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
            if (val !== '') {
                _input.setValidators([
                    Validators.pattern(/^\d*(?:[.,]\d{1,2})?$/)
                ]);
            } else {
                _input.clearValidators();
            }
            _input.updateValueAndValidity();
        });
    }
}
